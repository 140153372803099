export default {
  layout: {
    welcome: "ยินดีต้อนรับ",
    title: "คุณต้องการขายแพ็กเกจหรือเวาเชอร์",
    createPackage: "สร้างแพ็กเกจ",
    createPackageText:
      "ประเภทแพ็กเกจที่เปิดขายเฉพาะกับฮังกรี้ฮับ คุณจะได้ทำการเปิดจำนวนที่นั่ง สร้างเมนูอาหารและตั้งราคาแพ็กเกจ.",
    createVoucher: "สร้างเวาเชอร์",
    createVoucherText:
      "เวาเชอร์คือบัตรกำนัลที่พักและร้านอาหารราคาพิเศษ สำหรับการจองที่พักและการเข้าใช้บริการร้านอาหารโดยตรงกับพาร์ทเนอร์ของเรา คุณจะได้สร้างรายละเอียดเวาเชอร์ จำนวนที่ต้องการขายและราคาสุทธิ.",
    getStateButton: "เริ่มกันเลย",
  },
  logged: {
    galleryManagement: "การจัดการแกลเลอรี่s",
    searchBuilding: "ค้นหา",
    pleaseChooseYourLocation: "กรุณาเลือกที่อยู่ของคุณ",
    addMoreButton: "เพิ่มอีก",
    deleteButton: "ลบ",
    textStep: "ขั้นต่อ",
    textOf: "ที่",
    stepStoreSetting: "การตั้งค่า",
    stepAllotmentManagement: "การจัดการการที่นั่ง",
    stepPackage: "แพ็กเกจและเมนู",
    stepDocument: "เอกสาร",
    supportTitle: "มีคำถาม? คลิกที่ช่วยเหลือสำหรับการสนับสนุน",
    textNoteVerify: "กรุณาทำตามขั้นตอนเพื่อยืนยันบัญชีของคุณ",
    textNoteVerifySuccess: "เราจะติดต่อกลับไปภายใน 5-7 วัน",
    register: "ลงทะเบียน",
    successVerifyTitle: "ขอบคุณ! บัญชีของคุณอยู่ระหว่างการตรวจสอบ!",
    buttonEditAndResubmit: "แก้ไขข้อมูลและส่งใหม่",
    textComplete: "สำเร็จ!",
    restaurantDetailsFacilities:
      "รายละเอียดร้านอาหาร สถานที่ และสิ่งอำนวยความสะดวก",
    hotelDetailsLocationFacilities: "Location & facilities",
    experienceDetailLocationFacilities: "Location & facilities",
    primary: "หลัก",
    secondary: "รอง",
    primaryCuisine: "อาหารหลัก",
    secondaryCuisine: "อาหารรอง",
    primaryDiningStyle: "รูปแบบการรับประทานอาหารหลัก",
    secondaryDiningStyle: "รูปแบบการรับประทานอาหารรอง",
    displayTag: "แสดงแท็ก",
    subTag: "แท็กย่อย",
    displayTagCuisine: "แสดงแท็กอาหาร",
    subTagCuisine: "แท็กย่อยอาหาร",
    displayTagDiningStyle: "แสดงแท็กรูปแบบการรับประทานอาหาร",
    subTagDiningStyle: "แท็กย่อยรูปแบบการรับประทานอาหาร",
    maxChoices: "สูงสุด {0} ตัวเลือก",
    seeMore: "ดูเพิ่มเติม",
    seeLess: "See Less",
    locationGmap: "ตำแหน่งในแผนที่",
    city: "จังหวัด",
    popularZone: "โซนยอดนิยม",
    district: "เขต",
    shoppingMall: "ห้างสรรพสินค้า",
    btsRoute: "เส้นทางบีทีเอส",
    mrtRoute: "เส้นทาง MRT",
    facilities: "สิ่งอำนวยความสะดวก",
    restaurantInformation: "ข้อมูลร้านอาหาร",
    hotelInformation: "รายละเอียดโรงแรม",
    experienceInformation: "รายละเอียด Experience",
    mainContactPerson: "ผู้ติดต่อหลัก",
    country: "ประเทศ",
    currency: "สกุลเงิน",
    pleaseContact: "กรุณาติดต่อฝ่ายสนับสนุนหากคุณต้องการเปลี่ยนประเทศของคุณ",
    shortNameEng: "ชื่อสั้น (EN) สำหรับ SMS",
    shortNameTha: "ชื่อสั้น (TH) สำหรับ SMS",
    character: "(จำกัด 20 ตัวอักษร)",
    primaryStorePhone: "ร้านค้าหลัก หมายเลขโทรศัพท์",
    socialMedia: "Social Media & เว็บไซต์",
    secondaryStore: "หมายเลขโทรศัพท์ สำรอง",
    name: "ชื่อ",
    role: "หน้าที่",
    phoneNumber: "หมายเลขโทรศัพท์",
    emailAddress: "อีเมล",
    stepcreateVoucher: "สร้างเวาเชอร์",
    hotelBrand: "แบรนด์โรงแรม",
    starRating: "รีวิว",
    typeOfExperience: "ประเภทของ Experience",
    groupSize: "จำนวนคน",
    typeOfVenue: "ประเภทของสถานที่",
    pleaseExplainAbout: "กรุณาอธิบายเกี่ยวกับบริการอาหารของคุณ",
    logo: "โลโก้",
    coverImage: "ภาพปก",
    addPhoto: "เพิ่มรูปภาพ",
    changePhoto: "Change Photo",
    dropPhoto: "ใส่รูปที่นี่",
    dropPhotoMessage: "*ขนาดไฟล์ไม่เกิน {0}.",
    yourPicture: "รูปของคุณ",
    priority: "รูปภาพหน้าปกเพิ่มเติม",
    optional: "ไม่บังคับ",
    thumbnail: "รูปภาพ",
    gallery: "จัดการแกลเลอรี่",
    photomanagement: "การจัดการภาพถ่าย",
    cuisineAndDiningStyle: "อาหารและรูปแบบการรับประทานอาหาร",
    cuisine: "อาหาร",
    diningStyle: "รูปแบบการรับประทานอาหาร",
    location: "ตำแหน่งในแผนที่",
    place: "สถานที่",
    specifyName: `Please specify the name (s)`,
    specifyLink: `โปรดระบุ Link`,
    onSiteRestaurant: `
    <span class="d-block"> ร้านอาหาร</span>
    <span class="d-block">
    ที่อยู่ที่เดียวกัน
      <span class="red--text">*</span>
    </span>`,
    availableRoom: `
    <span>
      ห้องที่ว่างอยู่
      <span class="red--text">*</span>
    </span>`,
    averageRoomRate: `
    <span class="d-block"> Average </span>
    <span class="d-block">
      Room Rate
      <span class="red--text">*</span>
    </span>`,
  },
  form: {
    howdid: "คุณได้ยินอย่างไร",
    hear: "เกี่ยวกับ Hungry Hub",
    howDidHear: "คุณได้ยินอย่างไรเกี่ยวกับ Hungry Hub ?",
    title: "ลงทะเบียน",
    aboutYourOutlet: "เกี่ยวกับร้าน",
    outletType: "ประเภทร้าน",
    pleaseSelectOne: "เลือกอย่างน้อยหนึ่งอย่าง",
    country: "ประเทศ",
    city: "จังหวัด",
    select: "เลือก",
    name: "ชื่อ",
    primaryPhoneNo: "หมายเลขโทรศัพท์",
    operatingHours: `<span class="d-block">เวลาเปิด</span><span class="d-block">(เปิด - ปิด)
    <span class="red--text">*</span></span>`,
    LocationInGoogleMap: "ตำแหน่งใน Google Map",
    description: "คำอธิบาย",
    en: "(EN)",
    th: "(TH)",
    primaryCuisine: "อาหาร",
    numberOfBranch: "จำนวนสาขา",
    es: "(es)",
    anyNearLandmark: "สถานที่สำคัญที่อยู่ใกล้",
    restaurant: "ร้านอาหาร",
    hotel: "โรงแรม",
    experience: "ประสบการณ์",
    lorem:
      "— Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
    howDoWeContactYou: "ช่องทางการติดต่อ",
    yourName: "ชื่อ",
    yourPhoneNumber: "หมายเลขโทรศัพท์",
    yourRole: "หน้าที่",
    yourEmailAddress: "อีเมล",
    howDidYouHearAboutHungryHub: "คุณรู้จัก Hungry Hub จากที่ไหน",
    accountInformation: "รายละเอียดบัญชี",
    youWillUseThis: "คุณจะต้องใช้ข้อมูลนี้เข้าสู่ระบบ Partner Portal",
    confirmPassword: "ยืนยันรหัสผ่าน",
    thankYouPleaseCheck:
      "ขอบคุณค่ะ รายละเอียดการเข้าสู่ระบบถูกส่งไปทางอีเมลเรียบร้อยแล้ว",
    verified: "ยืนยันสำเร็จ!",
    youHaveSuccessfully: "คุณได้ยืนยันบัญชีของคุณเรียบร้อยแล้ว",
    emailAddress: "อีเมล",
    password: "รหัสผ่าน",
    charactersLimit: "*5000 ตัวอักษร",
    tooltips: {
      restaurant: "เลือกตัวเลือกนี้ถ้าคุณเป็นร้านอาหารหรือร้านอาหารในโรงแรม.",
      hotel: "เลือกตัวเลือกนี้ถ้าคุณเป็นโรงแรมและต้องการเปิดระบบสำหรับจองห้อง.",
      experience: "เลือกตัวเลือกนี้หากคุณจัดเตรียมประสบการณ์/Experience.",
    },
  },
};
