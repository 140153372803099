export default {
  report: "Report",
  weeklyReport: "Weekly Report",
  monthlyReport: "Monthly Report",
  reportByHour: "Report by Hour",
  reportByDay: "Report by Day",
  filters: "Filters",
  ratingBySelectedDate: "Rating by selected date",
  ratingStar: "Rating Star",
  total: "Total",
  average: "Average",
  hour: "Hour",
  totalBooking: "Total Booking",
  totalOrder: "Total Order",
  totalAdult: "Total Adult",
  totalKids: "Total Kids",
  totalSeats: "Total Seats",
  availableSeats: "Available Seats",
  totalNumberOfDineInAndPeople: "Total Number of Dine In and People",
  partySize: "Party Size",
  noOfPeopleOrNoOfBooking: "No. of People / No. of Booking",
  packageReportByTimeOfDay:
    "Package report [dine in / delivery] by time of day",
  grandTotal: "Grand total",
  serviceTypeReportByTimeOfDay:
    "Service type report [delivery / dine in] by time of day",
  dineIn: "Dine In",
  delivery: "Delivery",
  selfPickup: "Self-Pickup",
  soldOutReport: "Sold Out Report",
  package: "Package",
  mon: "Mon",
  tue: "Tue",
  wed: "Wed",
  thu: "Thu",
  fri: "Fri",
  sat: "Sat",
  sun: "Sun",
  dineInByDay: "Dine In By Day",
  date: "Date",
  arrived: "Arrived",
  pendingUpcomingReservations: "Pending / Upcoming Reservations",
  noShow: "No Show",
  cancelled: "Cancelled",
  rejected: "Rejected",
};
