export default {
  bookingList: "Booking List",
  bookingHistory: "Booking History",
  buttonStatus: "status",
  buttonEdit: "Edit",
  buttonOther: "Other ",
  bookingID: "Booking ID",
  date: "Date",
  time: "Time",
  customerName: "Customer Name",
  packageType: "Package Type ",
  packageDetails: "Package Details",
  discountDetails: "Discount Details",
  partySize: "Party Size",
  paidAmount: "Paid Amount",
  specialRequest: "Special Request",
  action: "Action",
  orderStatus: "Order Status:",
  orderLater: "Order Later",
  buttonMenu: "Menu",
  seemore: "See More",
  seeless: "See Less",
  buttonView: "View",
  moreDetail: "More Details",
  occasionTag: "Occasion tags",
  driverDetails: "Driver Details",
  noteToDriver: "Note To Driver",
  googleMapLink: "Google Map Link",
  address: "Address",
  est: "Est",
  due: "Due",
  distance: "Distance",
  deliveryfee: "Delivery Fee",
  selfPickup: "Self Pickup",
  delivery: "Delivery",
  noShow: "No Show",
  times: "Times",
  markAsArrived: "mark as arrived",
  cancel: "Cancel",
  approve: "Approve",
  reject: "Reject",
  sendConfirmationToUser: "send Confirmation to user",
  sendCustomSMS: "send Custom SMS",
  Sendreviewlink: "Send review link",
  createABooking: "Create a booking",
  bookingDetails: "Booking Details",
  tableName: "Table Name",
  tableNumber: "Table Number",
  status: "Status",
  name: "Name",
  phone: "Phone",
  adult: "Adults",
  kids: "Kids",
  email: "Email",
  notes: "Notes",
  paymentDetails: "Payment Details",
  paymentStatus: "Payment status",
  dueAmount: "Due amount",
  thb: "THB",
  packageName: "Package Name",
  totalPackagePrice: "Total package price",
  depositAmount: "Deposit amount",
  paymentMethods: "Payment Methods",
  promptPayQR: "PromptPay QR",
  debitCreditCard: "Debit/Credit Card",
  shopeePay: "Shopee Pay",
  trueMoneyWallet: "True Money Wallet",
  discountType: "Discount Type",
  totalPriceAfterDiscount: "Total price after discount",
  amount: "Amount",
  editBooking: "Edit Booking",
  seeReason: "see Reason",
  addMorePacks: "Add more packs",
  filters: "Filters",
  bookingStatus: "Booking Status",
  customerPhone: "Customer Phone ",
  startTime: "Start Time",
  // partySize: "Party Size",
  serviceType: "Service Type",
  specificPackage: "Specific Package",
  searchFilters: "Search Filters",
  rangeDate: "Range Date",
  singleDate: "Single Date",
  startDate: "Start Date",
  endDate: "End Date",
  clearAll: "Clear all ",
  showResults: "Show Results",
  all: "All",
  pendingArrival: "Pending Arrival",
  arrived: "Arrived",
  cancelled: "Cancelled",
  hhPendingArrival: "H@H Pending Arrival",
  deliveryCompleted: "Delivery Completed",
  orderReceived: "Order Received",
  allYouCanEat: "All You Can Eat (AYCE)",
  partyPack: "Party Pack (PP)",
  hh: "H@H (HH)",
  xperience: "Xperience (XP)",
  dineIn: "Dine In",
  selfPickUp: "Self-Pick up",
  noDeposit: "No deposit",
  hasDeposit: "Has deposit",
  prepaid: "Prepaid",
  export: "Export",
  exportBookingList: "Export Booking List",
  createBooking: "Create Booking",
  allBookings: "All bookings",
  pendingBookings: "Pending bookings",
  todayBookings: "Today’s booking",
  arrive: "Arrive",
  pleaseSelect: "Please Select",
  customSMS: "Custom SMS (160 characters)",
  booking: "Booking",
  discount: "Discount",
  bookingDeatails: "Booking Deatails",
  createdAt: "Created At",
  createBy: "Create By",
  cancelledBy: "CancelledBy",
  modifiedBy: "modifiedBy",
  customerDetails: "Customer Details",
  phoneNo: "Phone No.",
  myRestaurantBooking: "My Restaurant Booking",
  allRestaurantsBooking: "All Restaurants Booking",
  paymentSummary: "Payment Summary",
  total: "Total",
  method: "Methods",
  // deliveryFee: "Delivery Fee",
  driverId: "Driver ID",
  driverName: "Driver name",
  driverPhone: "Driver phone",
  drivingToRestaurant: "Driving  to restaurant",
  trackingLink: "Tracking Link",
  selectDate: "Select Date",
  voucher: "Voucher",
  pleaseChoose: "Please choose a package",
  show: "Show",
  approveThisBooking: "Approve this booking",
  rejectThisBooking: "Reject this booking",
  back: "Back",
  areYouSureApproveThisId:
    "Are you sure you want to approve this booking ID '{0}' ?",
  areYouSureRejectThisId:
    "Are you sure you want to reject this booking ID '{0}' ?",
  alertPendingBooking: `Alert! You have {0} PENDING BOOKINGS! Please “Approve” or “Reject” them as soon as possible.`,
  noResultFound: "No result found",
  sorryWeCouldntFindIt: "Sorry, we couldn’t find it.",
  tryClearingTheFiltersToDiscoverMoreResults:
    "Try clearing the filters to discover more results.",
  clearAllFilters: "Clear All Filters",
};
