export default {
  exportHistory: "Export History",
  reportName: "Report Name",
  booking: "Booking",
  voucher: "Voucher",
  package: "Package",
  billing: "Billing",
  analytic: "Analytic",
  onQueue: "On Queue",
  inProgress: "In Progress",
  done: "Done",
  reportID: "Report ID",
  reportType: "Report Type",
  status: "Status",
  createdAt: "Created At",
  finishedAt: "Finished At",
  startDate: "Start Date",
  endDate: "End Date",
  action: "Action",
  download: "Download",
};
