import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user.store";

export const useToastStore = defineStore({
  id: "toast",
  state: () => ({
    visible: false,
    content: "",
    title: "",
    iconType: "",
    timeout: 5000,
    currentTime: 0,
    timer: null,
    timerVisible: null,
    info: null,
  }),
  actions: {
    showToast(context) {
      const userStore = useUserStore();
      const restaurantName = userStore.getRestaurantName;
      const restaurantID = userStore.getRestaurantID;

      if (this.visible) {
        this.resetToast();
        console.log(this.timer);
        clearTimeout(this.timer);
      }
      this.timerVisible = setTimeout(() => {
        this.visible = true;
      }, 250);
      this.title = context.title;
      this.info = `${restaurantName} - ${restaurantID}`;
      this.content = context.content;
      this.iconType = context.iconType;
      this.timeout = context.timeout || 5000;
      this.syncPbar();
    },
    resetToast() {
      this.visible = false;
      this.title = "";
      this.content = "";
      this.iconType = "";
      this.timeout = 5000;
      this.currentTime = 0;
      clearTimeout(this.timerVisible);
    },
    syncPbar() {
      this.timer = setTimeout(() => {
        this.currentTime += 100;
        if (this.timeout <= this.currentTime) {
          this.resetToast();
          this.currentTime = 0;
        } else {
          this.syncPbar();
        }
      }, 100);
    },
  },
});
