export default {
  exportHistory: "ประวัติการส่งออก",
  reportName: "ชื่อรายงาน",
  booking: "การจอง",
  voucher: "บัตร",
  package: "แพ็คเกจ",
  billing: "การเรียกเก็บเงิน",
  analytic: "การวิเคราะห์",
  onQueue: "อยู่ในคิว",
  inProgress: "กำลังดำเนินการ",
  done: "เสร็จแล้ว",
  reportID: "รหัสรายงาน",
  reportType: "ประเภทรายงาน",
  status: "สถานะ",
  createdAt: "สร้างเมื่อ",
  finishedAt: "เสร็จเมื่อ",
  startDate: "วันที่เริ่ม",
  endDate: "วันที่สิ้นสุด",
  action: "การดำเนินการ",
  download: "ดาวน์โหลด",
};
