const cookieName = "partner_portal"; // You can change this to your desired cookie name
const cookieValue = "user_session"; // You can change this to your desired cookie value

export const setCookie = ({ rememberMe }) => {
  if (rememberMe) {
    // Set cookie to expire in 2 months (60 days)
    const date = new Date();
    date.setMonth(date.getMonth() + 2);
    document.cookie = `${cookieName}=${cookieValue}; expires=${date.toUTCString()}; path=/`;
  } else {
    // Set session cookie (no expiration, removed when the browser closes)
    document.cookie = `${cookieName}=${cookieValue}; path=/`;
  }
};

export const checkCookie = () => {
  // Get the document cookie string
  const cookieString = document.cookie;

  // Check if the cookie exists by looking for the cookie name in the cookie string
  const cookieExists = cookieString
    .split("; ")
    .find((row) => row.startsWith(`${cookieName}=`));

  // If the cookie does not exist, call the logout function
  if (!cookieExists) {
    return false;
  }

  return true;
};
