import documents from "./documents";
import layout from "./layout";
import login from "./login";
import validationForm from "./validationForm";
import forgotPassword from "./forgotPassword";
import resetPassword from "./resetPassword";
import register from "./register";
import allotments from "./allotments";
import accountManagement from "./accountManagement";
import analyticsPerformance from "./analyticsPerformance";
import ranking from "./ranking";
import help from "./help";
import marketing from "./marketing";
import billing from "./billing";
import exportPage from "./exportPage";
import reviewManangement from "./reviewManagement";
import { dashboard } from "./dashboard";
import booking from "./booking";
import voucher from "./voucher";
import packages from "./package";
import { button } from "./button";
import filterPage from "./filterPage";
import toast from "./toast";
import menu from "./menu";
import successDialog from "./successDialog";
import exportHistory from "./exportHistory";
import selectBranch from "./selectBranch";
import report from "./report";

export default {
  title: "Portal Partner TH",
  layout,
  login,
  documents,
  validationForm,
  forgotPassword,
  resetPassword,
  register,
  button,
  allotments,
  accountManagement,
  analyticsPerformance,
  ranking,
  help,
  marketing,
  menu,
  billing,
  exportPage,
  reviewManangement,
  dashboard,
  booking,
  package: packages,
  voucher,
  filterPage,
  toast,
  successDialog,
  exportHistory,
  selectBranch,
  report,
};
