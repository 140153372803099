export default {
  billing: "การเรียกเก็บเงิน",
  billingOverview: "ใบแจ้งหนี้",
  totalDueAmountExcludingTax: "ยอดรวมที่ต้องชำระ (ยังไม่รวมภาษี)",
  totalGMV: "GMV ทั้งหมด",
  totalRevenue: "รายได้ทั้งหมด",
  totalRevenueGMV: "รายได้ทั้งหมด (GMV)",
  totalPrepaymentAndCodes: "เงินล่วงหน้าทั้งหมด + รหัส",
  commissionBreakdown: "รายละเอียดค่าคอมมิชชั่น",
  packages: "แพ็กเกจ",
  hungryHome: "Hungry@home",
  allYouCanEat: "All You Can Eat",
  packageName: "ชื่อแพ็กเกจ",
  price: "ราคา",
  totalCover: "คนที่จองทั้งหมด",
  commission: "Commission ",
  experience: "Experience",
  partyPack: "Party Pack",
};
