import { getCurrentInstance } from "vue";

export function logRollbarError(message, payload = null) {
  if (getCurrentInstance()) {
    const { proxy } = getCurrentInstance();

    if (proxy && proxy.$rollbar) {
      if (payload === null) {
        proxy.$rollbar.error(message);
      } else {
        proxy.$rollbar.error(message, payload);
      }
    } else {
      console.error("Rollbar instance is not available.");
    }
  }
}
