export default {
  accountManagement: "Account Management",
  profile: "Profile",
  store: "Store",
  userAccessRight: "User & Access Right",
  status: "Status",
  generalInformation: "General information",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  role: "Role",
  contactNumber: "Contact Number",
  changePassword: "Change Password",
  oldPassword: "Old Password",
  newPassword: "New Password",
  cancel: "Cancel",
  update: "Update",
  phone: "Phone",
  userId: "User ID",
  name: "Name",
  createdAt: "Created At",
  lastOnline: "Last Online",
  action: "Action",
  owner: "Owner",
  contributor: "Contributor",
  viewer: "Viewer",
  lastSeen: "Last Seen",
  joined: "Joined",
  createANewUser: "Create a new user",
  password: "Password",
  confirmPassword: "Confirm Password",
  undo: "Undo",
  createNewUser: "Create New User",
  create: "Create",
  export: "Export",
  resetPassword: "Reset Password",
  receiveLinkResetPassword:
    "User will receive a link to reset password via email.",
  editAccessRight: "Do you want to edit access right",
  confirmDeleteAccount: "Do you want to delete account",
  editUser: "Edit User",
  confirmDeleteImage: "Are you sure you want to delete this image?",
  giveAccessToHungryhubTeam: "Give access to Hungryhub Team",
  accessWillBeGiven:
    "Access will be given to hungryhub team until next 24 hours",
  accessWasGiven: "Access was given to hungryhub team until next 24 hours",
  close: "Close",
  confirm: "Confirm",
};
